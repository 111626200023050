import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/scss/variables.scss'
import tr from 'vuetify/src/locale/tr'

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { tr },
        current: 'tr',
    },
    icons: {
        iconfont: 'mdi', // Material Design Icons
    },
    theme: {
        dark: false,
        disable: false, // Tema devre dışı bırakılmamalı
        options: {
            customProperties: true, // CSS değişkenlerini etkinleştirir
        },
    },
    // Global olarak sessiz modu etkinleştirme
    // Bu, konsolda Vuetify uyarılarını göstermez
    // Geliştirme sırasında false, üretimde true olarak ayarlanabilir
    silent: process.env.NODE_ENV === 'production',
});