

import {Component, Vue} from "vue-property-decorator";
import {ProgramPaths} from "@/enum/ProgramPaths";
import HesapBilgileri from "@/views/ofispro/HesapBilgileriView.vue";
import {store} from "@/store";

@Component({
  computed: {
    store() {
      return store
    }
  },
  components: {HesapBilgileri}
})
export default class UserInfoCard extends Vue {
  menu: Boolean = false;
  userData = JSON.parse(sessionStorage.user);

  hesapBilgileri() {
    this.$router.push(ProgramPaths.ofispro + '/hesap-bilgileri/');
  }

  barokartBakiyeYukle() {
    window.open('https://barokart.com.tr/TL_Yukle.aspx', '_blank');
  }

  async switchTenant(item: any) {
    this.$toast.info("Oturum değişikliği yapılıyor");
    this.$store.commit("setTenantId", item.tenant_id);
    let userRes = await this.$http.get("/api/v1/ben")
    this.$store.commit('setUser', userRes);
    location.reload();
  }

  get userInitials(): string {
    if (this.userData && this.userData.first_name && this.userData.last_name) {
      return (this.userData.first_name[0] + this.userData.last_name[0]).toUpperCase();
    }
    return '';
  }

  async created() {
    await this.$store.dispatch("getTenantList");
  }

  exit() {
    localStorage.clear();
    sessionStorage.clear();
    this.userData = null;
    location.href = this.$store.state.ssoUri + "force-logout?redirect_uri=" + location.origin;
  }
}
