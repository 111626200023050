// TODO Button Stiti Bozuluyor
<template>
  <v-dialog persistent :width="width" v-model="show">
    <template v-slot:activator="{ on: onDialog }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onToolTip }" v-on="onToolTip">
          <span :style="buttonStyle" v-on="tooltip ? onToolTip : null">
            <v-btn
                v-if="label && !isOnlyText"
                v-on="onDialog"
                :outlined="isOutlined"
                :color="buttonColor"
                :disabled="disabled"
                :width="buttonWidth"
                :height="buttonHeight"
                :class="[customButtonClass, `button-theme-${buttonTheme}`]"
                elevation="0"
            >
              <span
                  style="font-size: small"
                  :style="{color: font_color, height: height}"
              >
                {{ label }}
              </span>
              <v-icon
                  v-if="iconStr"
                  :small="isMedium === false && x_large !== true"
                  :x-large="x_large"
                  :style="{color: font_color}"
              >
                {{ iconStr === "kapakHesabi" ? "" : iconStr }}
              </v-icon>
            </v-btn>
            <a v-else-if="isOnlyText" v-text="label" v-on="onDialog"/>
            <v-btn
                v-else-if="isBigButton"
                v-on="onDialog"
                :x-large="x_large"
                :outlined="isOutlined"
                :disabled="disabled"
                :color="big_button_color"
                :style="buttonStyle"
                :class="[customButtonClass, {'cover-all': cover_all}, `button-theme-${buttonTheme}`]"
                elevation="0"
            >
              <v-icon v-if="iconVis" :color="iconColor" :x-large="x_large" :large="large">
                {{ iconStr }}
              </v-icon>
              <span v-if="big_button_label != null" :style="{color: iconColor}">{{ big_button_label }}</span>
            </v-btn>
            <v-btn
                v-else
                v-on="onDialog"
                :fab="isDark"
                :dark="isDark"
                :small="isSmall"
                :x-large="x_large"
                :color="buttonColor"
                :disabled="disabled"
                :outlined="isOutlined"
                :icon="!isDark || isIcon"
                :x-small="isMedium === false"
                :style="buttonStyle"
                :class="[customButtonClass, {'cover-all': cover_all}, `button-theme-${buttonTheme}`]"
                elevation="0"
            >
              <v-icon :x-large="x_large" :style="{color: iconColor}">{{ iconStr }}</v-icon>
            </v-btn>
          </span>
        </template>
        <span v-if="tooltip" v-html="tooltip"/>
      </v-tooltip>
    </template>
    <v-row class="ma-0 pa-0">
      <v-col class="ma-0 pa-0">
        <v-card elevation="0" class="dialog-style ma-auto">
          <v-card-title v-if="title || title === ''">{{ title }}</v-card-title>
          <v-card-text>
            <slot
                :formData="localValue"
                :onInput="onInput"
                :onClose="onClose"
                :onSuccess="onSuccess"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormDialog',
  props: {
    value: {}, // Form verisi
    title: { type: String, default: null }, // Dialog başlığı
    iconVis: { type: Boolean, default: true }, // İkon görünürlüğü
    icon: { type: String, default: '' }, // İkon adı
    iconProp: { type: [String, Boolean], default: false }, // İkon özelliği
    label: { type: String, default: '' }, // Buton etiketi
    color: { type: String, default: '' }, // Buton rengi
    dark: { type: [String, Boolean], default: false }, // Koyu tema
    small: { type: [String, Boolean], default: false }, // Küçük buton
    medium: { type: [String, Boolean], default: false }, // Orta boy buton
    large: { type: [String, Boolean], default: false }, // Büyük buton
    isOutlined: { type: [String, Boolean], default: false }, // Çerçeveli buton
    disabled: { type: Boolean, default: false }, // Devre dışı buton
    width: { type: [String, Number], default: '600' }, // Dialog genişliği
    buttonWidth: { type: [String, Number], default: null }, // Buton genişliği
    buttonHeight: { type: [String, Number], default: '' }, // Buton yüksekliği
    isBigButton: { type: Boolean, default: false }, // Büyük buton mu?
    x_large: { type: Boolean, default: false }, // Çok büyük buton
    cover_all: { type: [String, Boolean], default: false }, // Tam kaplama
    iconColor: { type: String, default: '' }, // İkon rengi
    big_button_label: { type: String, default: '' }, // Büyük buton etiketi
    font_color: { type: String, default: '' }, // Yazı rengi
    height: { type: String, default: '' }, // Yükseklik
    big_button_color: { type: String, default: '' }, // Büyük buton rengi
    isResetLocalValueOnClose: { type: Boolean, default: true }, // Kapanışta sıfırlama
    defaultValue: {}, // Varsayılan değer
    onlyText: { type: [String, Boolean], default: false }, // Sadece metin
    tooltip: { type: String, default: null }, // Tooltip metni
    buttonStyle: { type: String, default: '' }, // Buton stili
    customButtonClass: { type: String, default: '' }, // Özel buton sınıfı
    buttonTheme: { type: String, default: 'default' }, // Buton teması
  },
  data() {
    return {
      show: false,
      buttonColor: this.color || "badge badge-gray",
      iconStr: this.icon || "mdi-plus-thick",
      localValue: this.initializeLocalValue(),
    };
  },
  computed: {
    isMedium() {
      return this.medium || this.medium === "";
    },
    isSmall() {
      return this.small || this.small === "";
    },
    isIcon() {
      return this.iconProp || this.iconProp === "";
    },
    isDark() {
      return this.dark || this.dark === "";
    },
    isOnlyText() {
      return this.onlyText || this.onlyText === "";
    },
  },
  watch: {
    value: {
      handler() {
        this.updateLocalValue();
      },
      deep: true,
    },
    show(newVal) {
      this.$emit('onVisibilityChange', newVal);
    },
  },
  methods: {
    initializeLocalValue() {
      if (Array.isArray(this.value)) {
        return this.value ? [...this.value] : (this.defaultValue ? JSON.parse(JSON.stringify(this.defaultValue)) : []);
      } else {
        return this.value ? {...this.value} : (this.defaultValue ? JSON.parse(JSON.stringify(this.defaultValue)) : {});
      }
    },
    updateLocalValue() {
      if (Array.isArray(this.value)) {
        this.localValue = this.value ? [...this.value] : [];
      } else {
        this.localValue = this.value ? {...this.value} : {};
      }
    },
    onClose() {
      this.show = false;
      if (this.isResetLocalValueOnClose) {
        this.clearLocalValue();
      }
    },
    onInput(val) {
      this.localValue = val;
      this.$emit("input", this.localValue);
    },
    onSuccess(data) {
      this.onClose();
      this.$emit("success", data);
    },
    clearLocalValue() {
      if (!this.localValue.id) {
        if (this.defaultValue) {
          this.localValue = JSON.parse(JSON.stringify(this.defaultValue));
        } else {
          this.resetValidation(this);
          this.localValue = {};
        }
      }
    },
    resetValidation(component = this) {
      component.$children.forEach((child) => {
        if (child.$options._componentTag === 'form-wrapper') {
          child.$refs.form.resetValidation();
        }
        if (child.$children.length > 0) {
          this.resetValidation(child);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.cover-all {
  width: 100%;
  height: 100%;
}
.dialog-style {
  border-radius: 12px;
}
.button-theme-gray {
  background-color: hsla(210, 15%, 44%, 1) !important;
  color: white !important;
}

.button-theme-white {
  background-color: white !important;
  color: hsla(210, 15%, 44%, 1) !important;
}
.button-theme-white .v-icon {
  color: hsla(210, 15%, 44%, 1) !important;
}
.button-theme-blue {
  background-color: hsla(206, 90%, 67%, 1) !important;
  color: white !important;
}
.button-theme-blue .v-icon {
  color: white !important;
}

.button-theme-text-blue {
  color: hsla(206, 90%, 67%, 1) !important;
  background-color: white !important;
}

.button-theme-text-blue .v-icon {
  color: hsla(206, 90%, 67%, 1) !important;
}


</style>
